interface ILinkedContent {
  header?: string;
  subheader?: string;
  description?: string;
  linkText: string;
  link: string;
}

interface OfficeContent {
  header?: string;
  subheader?: string;
}

interface IContactPageContent {
  title: string;
  contact: ILinkedContent;
  queries: {
    pricing: ILinkedContent;
    investor: ILinkedContent;
    media: ILinkedContent;
  };
  offices: {
    header: string;
    description: string;
    london: OfficeContent;
    newcastle: OfficeContent;
    cramlington: OfficeContent;
  };
}

const content: IContactPageContent = {
  title: 'Contact ThingCo',
  contact: {
    header: 'Contact',
    subheader:
      'If you have any general queries about ThingCo or any of our services please contact us using the details below.',
    linkText: 'general@thingco.com',
    link: 'mailto:general@thingco.com',
  },
  queries: {
    pricing: {
      header: 'Pricing Queries',
      description:
        'Competitively priced and tailored to each partner depending on the specific business need. Contact us to understand more.',
      linkText: 'pricing@thingco.com',
      link: 'mailto:pricing@thingco.com',
    },
    investor: {
      header: 'Investor Queries',
      description:
        'Open to conversations with potential investors who share the same vision and values. Contact us for a conversation to understand more about us.',
      linkText: 'investor@thingco.com',
      link: 'mailto:investor@thingco.com',
    },
    media: {
      header: 'Media Queries',
      description: 'ThingCo are breaking ground with every move. Contact us to learn more.',
      linkText: 'thingco@harrisonsadler.com',
      link: 'mailto:thingco@harrisonsadler.com',
    },
  },
  offices: {
    header: 'Our Offices',
    description: 'Here are our ThingCo offices!',
    london: {
      header: 'London Office',
      subheader: 'WeWork, 51 Eastcheap, London, EC3M 1JP',
    },
    newcastle: {
      header: 'Newcastle Office',
      subheader: 'Neon Building, Quorum Business Park, Newcastle, NE12 8BU',
    },
    cramlington: {
      header: 'Cramlington Office',
      subheader: 'Unit 21, Endeavour Park, Nelson Park West, Cramlington, NE23 1XA',
    },
  },
};

export default content;
