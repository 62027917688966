import styled from 'styled-components';
import { ContentHeader, ContentSubheader, PageHeader } from '../../styles';
import Img from 'gatsby-image';
import { COLOR } from '../../constants';

interface ILinkedContentContainer {
  width: string;
  color: string;
}

export const LinkedContentContainer = styled.div<ILinkedContentContainer>`
  display: flex;
  flex-direction: column;
  flex: 1;
  margin: 50px 0px;
  width: ${props => props.width};

  h1,
  h2,
  h3,
  p,
  a {
    color: ${props => props.color};
  }
`;

export const Link = styled.a`
  font-size: 18px;
  margin: 15px 0px;
  color: ${COLOR.WHITE};
`;

export const LinkedContentImage = styled(Img)`
  margin: 25px 0px;
`;

export const LinkedContentPageHeader = styled(PageHeader)`
  margin: 15px 0px;
`;

export const LinkedContentHeader = styled(ContentHeader)`
  margin: 15px 0px;
`;

export const LinkedContentSubheader = styled(ContentSubheader)`
  margin: 5px 0px;
  white-space: pre-line;
`;
