import React from 'react';

import { FluidObject } from 'gatsby-image';
import {
  LinkedContentContainer,
  Link,
  LinkedContentHeader,
  LinkedContentSubheader,
  LinkedContentPageHeader,
  LinkedContentImage,
} from './styles';
import { ContentParagraph } from '../../styles';

export interface ILinkedContentProps {
  isPageHeader?: boolean;
  image?: FluidObject;
  content: {
    header?: string;
    subheader?: string;
    description?: string;
    linkText?: string;
    link?: string;
  };
  width: string;
  color: string;
}

const LinkedContent = ({ image, content, isPageHeader, width, color }: ILinkedContentProps) => {
  const imageComponent = !!image ? <LinkedContentImage fluid={image} /> : null;
  const header = !!content.header && !isPageHeader ? <LinkedContentHeader>{content.header}</LinkedContentHeader> : null;
  const pageHeader =
    !!content.header && isPageHeader ? <LinkedContentPageHeader>{content.header}</LinkedContentPageHeader> : null;
  const subheader = !!content.subheader ? <LinkedContentSubheader>{content.subheader}</LinkedContentSubheader> : null;
  const description = !!content.description ? <ContentParagraph>{content.description}</ContentParagraph> : null;
  const link = content.link && content.linkText ? <Link href={content.link}>{content.linkText}</Link> : null;

  return (
    <LinkedContentContainer width={width} color={color}>
      {imageComponent}
      {header}
      {pageHeader}
      {subheader}
      {description}
      {link}
    </LinkedContentContainer>
  );
};

LinkedContent.defaultProps = {
  width: '100%',
};

export default LinkedContent;
