import React from 'react';
import { graphql } from 'gatsby';
import useIsDesktop from '../../lib/isDesktop';
import { Layout, Container } from '../../components';
import LinkedContent from '../../components/LinkedContent';
import content from './content';
import {
  ContentParagraph,
  FlexMobile,
  PaddingExtraSmall,
  PaddingSmall,
  ContentHeaderNoMargin,
  LinkedContentContainer,
} from '../../styles';
import { COLOR } from '../../constants';
import Slide from 'react-reveal/Slide';
import Fade from 'react-reveal/Fade';

const ContactPage = ({ data, path }: any) => {
  const isDesktop = useIsDesktop();
  const pageHeaderWidth = isDesktop ? '50%' : '100%';
  const paddingExtraSmall = isDesktop ? <PaddingExtraSmall /> : null;
  const smallPadding = isDesktop ? <PaddingSmall /> : null;

  return (
    <Layout isDesktop={isDesktop} path={path} title={content.title}>
      <Container backgroundcolor={COLOR.DARKBLUE} paddingVertical={'50px'}>
        <Slide left>
          <LinkedContent
            content={content.contact}
            isPageHeader={true}
            width={pageHeaderWidth}
            color={COLOR.WHITE}
          ></LinkedContent>
        </Slide>
      </Container>
      <Container flexDirection="column">
        <FlexMobile>
          <Slide bottom>
            <LinkedContent content={content.queries.pricing} color={COLOR.DARKBLUE}></LinkedContent>
          </Slide>

          {smallPadding}
          <Slide bottom>
            <LinkedContent content={content.queries.investor} color={COLOR.DARKBLUE}></LinkedContent>
          </Slide>

          {smallPadding}
          <Slide bottom>
            <LinkedContent content={content.queries.media} color={COLOR.DARKBLUE}></LinkedContent>
          </Slide>
        </FlexMobile>

        <Fade>
          <ContentHeaderNoMargin>{content.offices.header}</ContentHeaderNoMargin>
          <ContentParagraph>{content.offices.description}</ContentParagraph>
        </Fade>

        <LinkedContentContainer>
          <Slide bottom>
            <LinkedContent
              image={data.londonOfficeImage.childImageSharp.fluid}
              content={content.offices.london}
              color={COLOR.DARKBLUE}
            ></LinkedContent>
          </Slide>
          <Slide bottom>
            <LinkedContent
              image={data.newcastleOfficeImage.childImageSharp.fluid}
              content={content.offices.newcastle}
              color={COLOR.DARKBLUE}
            ></LinkedContent>
          </Slide>
          <Slide bottom>
            <LinkedContent
              image={data.cramlingtonOfficeImage.childImageSharp.fluid}
              content={content.offices.cramlington}
              color={COLOR.DARKBLUE}
            ></LinkedContent>
          </Slide>
        </LinkedContentContainer>

        {smallPadding}
      </Container>
    </Layout>
  );
};

export const query = graphql`
  query {
    londonOfficeImage: file(relativePath: { eq: "contact/london-office.png" }) {
      childImageSharp {
        fluid(maxWidth: 1920, quality: 50) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    newcastleOfficeImage: file(relativePath: { eq: "contact/newcastle-office.png" }) {
      childImageSharp {
        fluid(maxWidth: 1920, quality: 50) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    cramlingtonOfficeImage: file(relativePath: { eq: "contact/cramlington-office.png" }) {
      childImageSharp {
        fluid(maxWidth: 1920, quality: 50) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;

export default ContactPage;
